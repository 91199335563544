import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;
export class HomeConfirmV2 extends Component {
    static displayName = HomeConfirmV2.name;

    constructor(props) {
        super(props);

        this.state = {
            jpage: "",
            userLineID: "",
            cssbox1: "col-6 d-none",
            cssbox2: "col-11 p-0 d-none",
            cssbox3: "col-11 p-0 d-none",
            cssbox4: "text-center detail-confirmed d-none",
            cssbox5: "text-black",
            partnerid: this.props.location.state.partnerid,
            partnerbase64image: this.props.location.state.partnerbase64image,
            memberstatus: this.props.location.state.memberstatus,
            ismember:""
        };

        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async onSubmitForm() {
        //Good to go!! ->     
        this.setState({
            jpage: ""
        });

        //check Member - start
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UserId: this.state.userLineID,
                    partnerid: this.state.partnerid
                }
            )
        };

        await fetch('/api/memberupdate', requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.responsecode === "OK") {
                    //Member update -> show complete section
                    this.setState({
                        cssbox1: "col-6 d-none",
                        cssbox2: "col-11 p-0 ",
                        cssbox3: "col-11 p-0 d-none",
                        cssbox4: "text-center detail-confirmed",
                        memberstatus: "complete",
                        ismember: "YES"
                    });
                }
                else {
                    //Error
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: json.responsemessage
                        }
                    });
                }
            });
        //check Member - end

        //if ismember =YES -> getcardlive - start
        if (this.state.ismember === "YES") {
            const requestOptions2 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        UserId: this.state.userLineID,
                        partnerid: this.state.partnerid
                    }
                )
            };

            await fetch('/api/getcardlive', requestOptions2)
                .then(response => response.json())
                .then(json => {
                    if (json.responsecode === "OK") {
                        //Image found -> show image with Qr
                        this.setState({
                            cssbox1: "col-6 d-none",
                            cssbox2: "col-11 p-0 ",
                            cssbox3: "col-11 p-0 d-none",
                            cssbox4: "text-center detail-confirmed",
                            memberstatus: "complete",
                            partnerbase64image: json.partnerbase64image,
                            jpage: "done"
                        });
                    }
                    else {
                        //Image not found -> not found page
                        this.props.history.push({
                            pathname: "/HomeNotfound",
                            state: {
                                partnerid: this.state.partnerid,
                                ErrorTxt: json.responsemessage
                            }
                        });
                    }
                });
        }
        //getcardlive - end
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";
            //let LineuserPicture = "";              
        } else {
            liff.init({
                liffId: '2000223689-rJ6DYKw2'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId
                    });

                    this.setState({
                        jpage: "done"
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        var img01 = "data:image/jpeg;base64, " + this.state.partnerbase64image;

        var showBox01 = this.state.cssbox1;
        var showBox02 = this.state.cssbox2;
        var showBox03 = this.state.cssbox3;
        var showBox04 = this.state.cssbox4;
        var showBox05 = this.state.cssbox5;

        if (this.state.memberstatus === "Active") {
            //OverRide
            showBox01 = "col-6 d-none";
            showBox02 = "col-11 p-0 d-none";
            showBox03 = "col-11 p-0 ";
            showBox04 = "text-center detail-confirmed d-none";
            showBox05 = "text-black d-none";
        }
        else if (this.state.memberstatus === "complete") {
            //OverRide
            showBox01 = "col-6 d-none";
            showBox02 = "col-11 p-0";
            showBox03 = "col-11 p-0 d-none";
            showBox04 = "text-center detail-confirmed";
            showBox05 = "text-black d-none";
        }
        else {
            showBox01 = "col-6";
            showBox02 = "col-11 p-0 d-none";
            showBox03 = "col-11 p-0 d-none";
            showBox04 = "text-center detail-confirmed";
            showBox05 = "text-black";
        }

        return (
            <div className="form-signin shadow bg-home-enrollment position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/partnerconnect_h100_1.png")} alt="Partner Enrollment" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.jpage && this.state.jpage !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <div className={ showBox04}>
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div>
                                                <img src={img01} alt="Partner Enrollment" className="img-fluid w-100" />
                                            </div>
                                            <p className="mt-5 text-center lh-sm fs-6 fontkanit"><span className={showBox05} >กรุณายืนยันว่าข้อมูลที่แสดงอยู่ ถูกต้องหรือไม่</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-0 mb-0 justify-content-center">
                                    <div className={showBox01}>
                                        <div className="d-grid">
                                            <input type="button" className="btn btn-lg mt-2 mb-0 btn-register-4" onClick={this.onSubmitForm} defaultValue="Yes" />
                                        </div>
                                    </div>
                                    <div className={showBox01}>
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mt-2 mb-0 btn-register-completed">No</Link>
                                        </div>
                                    </div>

                                    <div className={showBox02}>
                                        <div className="d-grid">
                                            <span className="btn btn-lg mt-2 mb-0 btn-register-4" onClick={this.closeLIFF}>Enrollment Completed</span>
                                        </div>
                                    </div>

                                    <div className={showBox03}>
                                        <p className="mt-5 text-center lh-sm fs-6 fontkanit"><span className="text-black">This card already linked to another Line account.please contact administrator for more information.</span></p>
                                        <div className="d-grid">
                                            <input type="button" className="btn btn-lg mt-2 mb-0 btn-danger" onClick={this.closeLIFF} defaultValue="Close" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>


        );
    }
}