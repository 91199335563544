import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';

import { Home } from './components/Home';
import { HomeConfirm } from './components/HomeConfirm';
import { HomeConfirmV2 } from './components/HomeConfirmV2';
import { HomeNotfound } from './components/HomeNotfound';
import { HomeMember } from './components/HomeMember';
import { HomeMemberV2 } from './components/HomeMemberV2';
import { HomeSuspend } from './components/HomeSuspend';

import { NotInLine } from './components/NotInLine';
import { Mistake } from './components/Mistake';
import { MistakeLine } from './components/MistakeLine';

import { Term } from './components/Term';

import { Ztest } from './components/Ztest';
import { ZtestMember } from './components/ZtestMember';

import './components/style/custom.css'

/*console.log(new Date().getTime() + ' - App start');*/

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />

                <Route path='/HomeNotfound' component={HomeNotfound} />
                <Route path='/HomeConfirm' component={HomeConfirm} />
                <Route path='/HomeMember' component={HomeMember} />
                <Route path='/HomeConfirmV2' component={HomeConfirmV2} />
                <Route path='/HomeMemberV2' component={HomeMemberV2} />
                <Route path='/HomeSuspend' component={HomeSuspend} />

                <Route path='/NotInLine' component={NotInLine} />
                <Route path='/mistake' component={Mistake} />
                <Route path='/mistakeline' component={MistakeLine} />

                <Route path='/Term' component={Term} />

                <Route path='/Ztest' component={Ztest} />
                <Route path='/ZtestMember' component={ZtestMember} />
            </Layout>
        );
    }
}
