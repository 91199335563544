import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;
export class Ztest extends Component {
    static displayName = Ztest.name;

    constructor(props) {
        super(props);

        this.state = {
            jpage: "",
            userLineID: "",
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            partnerid: "",
            ans01: "",
            ismember: "",
            ismemberpartnerid: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "partnerid") {
            if (value.length > 13) {
                value = value.substring(0, 13)

                this.setState({
                    [name]: value
                });
            } else {
                this.setState({
                    [name]: value
                });
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.partnerid) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.ans01) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go!! ->
            this.setState({
                jpage: ""
            });

            //getcardlive - start
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        UserId: this.state.userLineID,
                        partnerid: this.state.partnerid
                    }
                )
            };

            await fetch('/api/getcardregister', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (json.responsecode === "OK") {
                        //Image found -> enrollmented confirm page
                        this.props.history.push({
                            pathname: "/HomeConfirm",
                            state: {
                                partnerid: json.partnerid,
                                partnerbase64image: json.partnerbase64image,
                                memberstatus: json.memberstatus
                            }
                        });
                    }
                    else {
                        //Image not found -> not found page
                        this.props.history.push({
                            pathname: "/HomeNotfound",
                            state: {
                                partnerid: this.state.partnerid
                            }
                        });
                    }
                });
            //getcardlive - end
        }
    }

    componentDidMount() {
        console.log(new Date().getTime() + ' - Demo home - init');
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine')

            let LineuserId = "U50c5cf35189e97a406cb17e89e1c3d65";
            let LineuserPicture = "";             

            //action - start
            //check Member - start
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        UserId: LineuserId
                    }
                )
            };
            console.log(new Date().getTime() + ' - Demo home - membercheck start');
            await fetch('/api/membercheck', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (json.responsecode === "OK") {
                        //Member active
                        this.setState({
                            ismember: "YES",
                            ismemberpartnerid: json.partnerid
                        });
                    }
                    else {
                        if (json.memberstatus === "0") {
                            //Member not exists or not active -> Show this page
                            this.setState({
                                userLineID: LineuserId
                            });
                        }
                        else {
                            //Member suspend -> Show this page
                            this.props.history.push('/HomeSuspend')
                        }  
                    }
                });
            console.log(new Date().getTime() + ' - Demo home - membercheck end');
            //check Member - end

            console.log(new Date().getTime() + ' - Demo home - getcard start');
            //if ismember =YES -> getcardlive - start
            if (this.state.ismember === "YES") {
                const requestOptions2 = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            UserId: this.state.userLineID,
                            partnerid: this.state.ismemberpartnerid
                        }
                    )
                };

                await fetch('/api/getcardlive', requestOptions2)
                    .then(response => response.json())
                    .then(json => {
                        if (json.responsecode === "OK") {
                            //Image found -> enrollmented confirm page
                            this.props.history.push({
                                pathname: "/ZtestMember",
                                state: {
                                    partnerid: json.partnerid,
                                    partnerbase64image: json.partnerbase64image
                                }
                            });
                        }
                        else {
                            //Image not found -> not found page
                            this.props.history.push({
                                pathname: "/HomeNotfound",
                                state: {
                                    partnerid: this.state.partnerid,
                                    ErrorTxt: json.responsemessage
                                }
                            });
                        }
                    });
            }
            //getcardlive - end
            console.log(new Date().getTime() + ' - Demo home - getcard end');

            this.setState({
                jpage: "done"
            });

                    //action - end
        } else {
            liff.init({
                liffId: '2000223689-rJ6DYKw2'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/membercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Member active
                                this.setState({
                                    ismember: "YES",
                                    ismemberpartnerid: json.partnerid
                                });
                            }
                            else {
                                //Member not exists or not active -> Show this page
                                this.setState({
                                    userLineID: LineuserId
                                });
                            }
                        });
                    //check Member - end

                    //if ismember =YES -> getcardlive - start
                    if (this.state.ismember === "YES") {
                        const requestOptions2 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(
                                {
                                    UserId: this.state.userLineID,
                                    partnerid: this.state.ismemberpartnerid
                                }
                            )
                        };

                        await fetch('/api/getcardlive', requestOptions2)
                            .then(response => response.json())
                            .then(json => {
                                if (json.responsecode === "OK") {
                                    //Image found -> enrollmented confirm page
                                    this.props.history.push({
                                        pathname: "/ZtestMember",
                                        state: {
                                            partnerid: json.partnerid,
                                            partnerbase64image: json.partnerbase64image
                                        }
                                    });
                                }
                                else {
                                    //Image not found -> not found page
                                    this.props.history.push({
                                        pathname: "/HomeNotfound",
                                        state: {
                                            partnerid: this.state.partnerid,
                                            ErrorTxt: json.responsemessage
                                        }
                                    });
                                }
                            });
                    }
                    //getcardlive - end

                    this.setState({
                        jpage: "done"
                    });

                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home-enrollment position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/partnerconnect_h100_1.png")} alt="Partner Enrollment" className="img-fluid mt-0 mb-0"/>
                </div>
                {
                    (this.state.jpage && this.state.jpage !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-5">
                                <form id="myform">
                                    <fieldset className="fieldset-form pt-1">
                                        <div className="form-group">
                                            <label className="mb-2 fontsodo-semibold">Partner ID</label>
                                            <input type="number" className="form-control" placeholder="Partner ID" required name="partnerid" id="partnerid" value={this.state.partnerid} onChange={this.onInputchange} />
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="ans01" defaultValue={this.state.ans01} onChange={this.onInputchange} />
                                            <label className="form-check-label text-blue-2 fontsodo-semibold"><small>I agree to the <Link to="/Term" className="text-blue-2" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>terms and conditions</strong></Link></small></label>
                                        </div>
                                        <div className="row text-center mt-3 mb-0 justify-content-center">
                                            <div className="col-6 p-0">
                                                <div className="d-grid">
                                                    <input type="button" className="btn btn-lg mt-2 mb-0 btn-register-4" onClick={this.onSubmitForm} defaultValue="Submit" />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {/* Custom Pop up1 */}
                                            <div id="popup1" className={this.state.ModelCss01}>
                                                <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                                <div className="text-center">
                                                    <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                                    <strong>และครบถ้วน</strong>
                                                </div>
                                                <ul className="text-start">
                                                    <li className={this.state.ModelCssinner01}>กรุณาระบุ Partner ID</li>
                                                    <li className={this.state.ModelCssinner02}>กรุณารับทราบและตกลงตามเ terms and conditions</li>
                                                </ul>
                                                <div className="text-center mt-3 mb-2">
                                                    <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}