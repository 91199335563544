import React, { Component } from 'react';

const liff = window.liff;
export class HomeMember extends Component {
    static displayName = HomeMember.name;

    constructor(props) {
        super(props);

        this.state = {
            userLineID: "",
            cssbox1: "box-footer-button position-relative",
            cssbox2: "box-footer-button position-relative d-none",
            partnerid: this.props.location.state.partnerid,
            partnerbase64image: this.props.location.state.partnerbase64image
        };
    }    

    closeLIFF() {
        liff.closeWindow();
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";
            //let LineuserPicture = "";                            
        } else {
            liff.init({
                liffId: '2000223689-rJ6DYKw2'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        var img01 = "data:image/jpeg;base64, " + this.state.partnerbase64image;

        return (
            <div className="form-signin shadow bg-home-enrollment position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/partnerconnect_h100_1.png")} alt="Partner Enrollment" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <div className="text-center detail-confirmed">
                                    <div className="row justify-content-center">
                                        <div className="col-11">
                                            <div>
                                                <img src={img01} alt="Partner Enrollment" className="img-fluid w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative" >
                                <div className="row text-center mt-0 mb-0 justify-content-center">
                                    <div className="col-10 p-0">
                                        <div className="d-grid">
                                            <input type="button" className="btn btn-lg mt-2 mb-0 btn-register-4" onClick={this.closeLIFF} defaultValue="Close" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                
            </div>


        );
    }
}