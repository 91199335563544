import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Mistake extends Component {
    static displayName = Mistake.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relativee">
                <div className="head-nav p-0">
                    <img src={require("./media/partnerconnect_h100_1.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                <div className="form-enter mt-0">
                    <div className="text-center" style={{ minHeight: '590px' }}>
                        <h4 className="pt-5 mt-5">มีข้อผิดพลาดเกิดขึ้น</h4>
                        <br />
                        {this.props.location.state.ErrorTxt}
                        <br />
                        <Link to="/">กรุณาลองใหม่อีกครั้ง</Link>
                    </div>
                </div>
            </div>
        );
    }
}