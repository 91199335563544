import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Term extends Component {
    static displayName = Term.name;

    render() {
        return (
            <div className="form-signin shadow bg-home-enrollment position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/partnerconnect_h100_1.png")} alt="Partner Enrollment" className="img-fluid mt-0 mb-0" />
                </div>
                <div className="form-enter p-3 pt-0 mt-4">
                    <div className="row p-3 pb-0">
                        <div className="col-12">
                            <h3 className="h5 text-start pt-0 mb-3 fontkanit">เงื่อนไขการใช้บริการ</h3>
                            <p className="fontkanit small">- ......<br />
                                - ......<br />
                                - ......<br />
                                - ......<br />
                                - ......</p>
                            <h3 className="h5 text-start pt-0 mb-3 fontkanit">Terms of Use</h3>
                            <p className="fontsodo-semibold small">- - ......<br />
                                - - ...... <br />
                                - - ...... <br />
                                - - ......<br />
                                - - ......</p>
                        </div>
                    </div>
                </div>
                <div className="box-footer-button position-relative mt-5">
                    <div className="row text-center mt-3 mb-0 justify-content-center">
                        <div className="col-6">
                            <div className="d-grid">
                                <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}